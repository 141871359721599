import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromDispensaries from './dispensaries.reducer';
import { DispensariesEffects } from './dispensaries.effects';
import { DispensariesFacade } from './dispensaries.facade';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(fromDispensaries.DISPENSARIES_FEATURE_KEY, fromDispensaries.reducer),
		EffectsModule.forFeature([DispensariesEffects]),
	],
	providers: [DispensariesFacade],
})
export class DispensariesStateModule {}
