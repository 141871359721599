import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from '../../_shared/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'leaf-layout',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit, OnDestroy {
	unsubscribe$ = new Subject();

	constructor(private breakpointObserver: BreakpointObserver, private userService: UserService, private router: Router) {}

	ngOnInit(): void {
		this.getCurrentUser();
	}

	getCurrentUser() {
		this.userService
			.getCurrentUser()
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(
				(user) => {
					if (user) {
						if (!environment.production) {
							console.log(user);
						}
						if (user.firstName && user.lastName) {
							localStorage.setItem('fullName', `${user.firstName} ${user.lastName}`);
							this.router.navigateByUrl('/secure/users/overview').then();
						} else {
							this.router.navigateByUrl('logout').then();
						}
					}
				},
				(error) => {
					if (!environment.production) {
						console.error(`CurrentUser => loading component => ${JSON.stringify(error)}`);
					}
					this.router.navigateByUrl('logout').then();
				}
			);
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
