import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as DispensariesActions from './dispensaries.actions';

import { DispensaryInterfaceWithId } from '../../_shared/interfaces/dispensary.interface';
import { Action, createReducer, on } from '@ngrx/store';

export const DISPENSARIES_FEATURE_KEY = 'dispensaries';

export interface DispensariesState extends EntityState<DispensaryInterfaceWithId> {
	selectDispensaryId: string | null; // which Clients record has been selected
	dispensariesLoaded: boolean; // has the Clients list been loaded
	dispensariesLoadError?: string | null; // last none error (if any)
	queryDispensaryLoadError?: string | null; // last none error (if any)

	addDispensaryError?: string | null; // last none error when adding a new client (if any)
	addDispensarySuccess: boolean; // last none error when adding a new client (if any)

	updateDispensaryError?: string | null; // last none error when adding a new client (if any)
	updateDispensarySuccess: boolean; // last none error when adding a new client (if any)

	deleteDispensaryError?: string | null; // last none error when adding a new client (if any)
	deleteDispensaryLoaded: boolean; // last none error when adding a new client (if any)
}

export interface DispensariesPartialState {
	readonly [DISPENSARIES_FEATURE_KEY]: DispensariesState;
}

export const dispensariesAdapter: EntityAdapter<DispensaryInterfaceWithId> = createEntityAdapter<DispensaryInterfaceWithId>({
	selectId: selectDispensaryId,
});

export const initialState: DispensariesState = dispensariesAdapter.getInitialState({
	// set initial required properties
	selectDispensaryId: null,
	dispensariesLoaded: false,
	addDispensarySuccess: false,
	updateDispensarySuccess: false,
	deleteDispensaryLoaded: false,
});

export function selectDispensaryId(a: DispensaryInterfaceWithId): string {
	// In this case this would be optional since primary key is id
	return a.id;
}

const dispensariesReducer = createReducer(
	initialState,

	on(DispensariesActions.loadDispensaries, (state) => {
		if (state.dispensariesLoadError) {
			delete state.dispensariesLoadError;
		}
		if (state.addDispensaryError) {
			delete state.addDispensaryError;
		}
		if (state.updateDispensaryError) {
			delete state.updateDispensaryError;
		}
		if (state.deleteDispensaryError) {
			delete state.deleteDispensaryError;
		}

		return {
			...state,
			dispensariesLoaded: false,
		};
	}),

	on(DispensariesActions.loadDispensariesSuccess, (state, { dispensaries }) => {
		return {
			...state,
			dispensariesLoaded: true,
		};
	}),

	on(DispensariesActions.loadDispensariesFailure, (state, { error }) => {
		if (error) {
			console.error(error);
		}
		return {
			...state,
			dispensariesLoadError: error,
		};
	}),

	// ACTIONS INITIATED FROM THIS CLIENT
	// FIREBASE WILL AUTOMATICALLY UPDATE STATE THROUGH QUERY!!

	/**
	 * Add Dispensaries from Client
	 */
	on(DispensariesActions.addDispensaries, (state, { dispensaries }) => {
		if (state.addDispensaryError) {
			delete state.addDispensaryError;
		}
		return {
			...state,
			addDispensariesSuccess: false,
		};
	}),

	on(DispensariesActions.addDispensariesError, (state, { error }) => {
		return {
			...state,
			addDispensariesError: error.message,
		};
	}),

	on(DispensariesActions.addDispensariesSuccess, (state, {}) => {
		if (state.addDispensaryError) {
			delete state.addDispensaryError;
		}
		return {
			...state,
			addDispensariesSuccess: true,
		};
	}),

	/**
	 * Delete Dispensaries from Client
	 */
	on(DispensariesActions.removeDispensaries, (state, { id }) => {
		if (state.deleteDispensaryError) {
			delete state.deleteDispensaryError;
		}
		return dispensariesAdapter.removeOne(id, {
			...state,
			deleteDispensariesLoaded: false,
		});
	}),

	on(DispensariesActions.removeDispensariesSuccess, (state, {}) => {
		if (state.deleteDispensaryError) {
			delete state.deleteDispensaryError;
		}
		return {
			...state,
			deleteDispensariesLoaded: true,
		};
	}),

	on(DispensariesActions.removeDispensariesError, (state, { error }) => {
		return {
			...state,
			deleteDispensariesError: error.message,
		};
	}),

	/**
	 * Update dispensaries from Client
	 */
	on(DispensariesActions.updateDispensaries, (state, { id, changes }) => {
		if (state.updateDispensaryError) {
			delete state.updateDispensaryError;
		}
		return dispensariesAdapter.updateOne(
			{
				id,
				changes,
			},
			{
				...state,
				updateDispensariesSuccess: false,
			}
		);
	}),

	on(DispensariesActions.updateDispensariesSuccess, (state, {}) => {
		if (state.updateDispensaryError) {
			delete state.updateDispensaryError;
		}
		return {
			...state,
			updateDispensariesSuccess: true,
		};
	}),

	on(DispensariesActions.updateDispensariesError, (state, { error }) => {
		return {
			...state,
			updateDispensariesError: error.message,
		};
	}),

	/**
	 * FIRSTORE ACTIONS
	 */
	on(DispensariesActions.fromFireStoreAddDispensaries, (state, { dispensary }) => {
		if (state.queryDispensaryLoadError) {
			delete state.queryDispensaryLoadError;
		}
		return dispensariesAdapter.setOne(dispensary, state);
	}),

	on(DispensariesActions.fromFireStoreModifyDispensaries, (state, { dispensary }) => {
		if (state.queryDispensaryLoadError) {
			delete state.queryDispensaryLoadError;
		}
		return dispensariesAdapter.updateOne(
			{
				id: dispensary.id,
				changes: dispensary,
			},
			state
		);
	}),
	on(DispensariesActions.fromFireStoreRemoveDispensaries, (state, { id }) => {
		if (state.queryDispensaryLoadError) {
			delete state.queryDispensaryLoadError;
		}
		return dispensariesAdapter.removeOne(id, state);
	}),
	on(DispensariesActions.queryFireStoreDispensariesError, (state, { error }) => {
		return {
			queryDispensariesLoadError: error.message,
			...state,
		};
	})
);

export function reducer(state: DispensariesState | undefined, action: Action) {
	return dispensariesReducer(state, action);
}
