import { dispensariesAdapter, DispensariesState, DISPENSARIES_FEATURE_KEY } from './dispensaries.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

// Lookup the 'Dispensary' feature state managed by NgRx
export const getDispensariesState = createFeatureSelector<DispensariesState>(DISPENSARIES_FEATURE_KEY);

const { selectAll, selectEntities, selectIds, selectTotal } = dispensariesAdapter.getSelectors();

export const getDispensariesLoaded = createSelector(getDispensariesState, (state: DispensariesState) => state.dispensariesLoaded);

export const getDispensariesLoadError = createSelector(getDispensariesState, (state: DispensariesState) => state.dispensariesLoadError);

export const getAddDispensariesSuccess = createSelector(getDispensariesState, (state: DispensariesState) => state.addDispensarySuccess);

export const getAddDispensariesError = createSelector(getDispensariesState, (state: DispensariesState) => state.addDispensaryError);

export const getUpdateDispensariesSuccess = createSelector(getDispensariesState, (state: DispensariesState) => state.updateDispensarySuccess);

export const getUpdateDispensariesError = createSelector(getDispensariesState, (state: DispensariesState) => state.updateDispensaryError);

export const getDeleteDispensariesSuccess = createSelector(getDispensariesState, (state: DispensariesState) => state.deleteDispensaryLoaded);

export const getDeleteDispensariesError = createSelector(getDispensariesState, (state: DispensariesState) => state.deleteDispensaryError);

export const getQueryDispensariesError = createSelector(getDispensariesState, (state: DispensariesState) => state.queryDispensaryLoadError);

// All Organization Dispensaries (Contactpersons of Clients with access and all Dispensaries)
export const getAllDispensaries = createSelector(getDispensariesState, (state: DispensariesState) => selectAll(state));

export const getDispensariesEntities = createSelector(getDispensariesState, (state: DispensariesState) => selectEntities(state));

export const getSelectedId = createSelector(getDispensariesState, (state: DispensariesState) => state.selectDispensaryId);

export const getSelected = createSelector(getDispensariesEntities, getSelectedId, (entities, selectedId) => selectedId && entities[selectedId]);

export const getDispensariesById = createSelector(getAllDispensaries, (dispensaries, props) => dispensaries.find((item) => item.id === props.id));
