import { Injectable, OnDestroy } from '@angular/core';

import * as DispensariesActions from './dispensaries.actions';
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { DispensaryService } from '../../_shared/services/dispensary.service';
import { DispensaryInterface, DispensaryInterfaceWithId } from '../../_shared/interfaces/dispensary.interface';
import { Actions, createEffect, ofType } from '@ngrx/effects';

@Injectable()
export class DispensariesEffects implements OnDestroy {
	unsubscribe$ = new Subject();

	queryClients$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DispensariesActions.queryFireStoreDispensaries),
			takeUntil(this.unsubscribe$),
			switchMap(() => this.dispensariesService.queryStateChangesDispensaries()),
			mergeMap((actions) => actions),
			map((action) => {
				if (action.payload.doc.exists) {
					const data = action.payload.doc.data() as DispensaryInterface;
					const payload = {
						...data,
						id: action.payload.doc.id ? action.payload.doc.id : null,
						exists: action.payload.doc.exists,
					} as DispensaryInterfaceWithId;
					// FireStore Events:  'added' | 'removed' | 'modified'
					switch (action.type) {
						case 'added':
							return DispensariesActions.fromFireStoreAddDispensaries({ dispensary: payload });
							break;
						case 'removed':
							return DispensariesActions.fromFireStoreRemoveDispensaries({ id: payload.id });
							break;
						case 'modified':
							return DispensariesActions.fromFireStoreModifyDispensaries({
								dispensary: payload,
							});
							break;
					}
				}
			}),
			catchError((error) => {
				return of(
					DispensariesActions.queryFireStoreDispensariesError({
						error,
					})
				);
			})
		)
	);

	updateDispensaries = createEffect(() =>
		this.actions$.pipe(
			ofType(DispensariesActions.updateDispensaries),
			switchMap((action) => {
				return this.dispensariesService.updateDispensaryWithId(action.changes, action.id);
			}),
			map(() => DispensariesActions.updateDispensariesSuccess({})),
			catchError((error) => {
				return of(
					DispensariesActions.updateDispensariesError({
						error,
					})
				);
			})
		)
	);

	addDispensaries$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DispensariesActions.addDispensaries),
			switchMap((action) => {
				console.log('ADD', action);
				return this.dispensariesService.addDispensary(action.dispensaries);
			}),
			map(() => DispensariesActions.addDispensariesSuccess({})),
			catchError((err: Error) => {
				console.error(err);
				if (err) {
					return of(DispensariesActions.addDispensariesError({ error: err }));
				}
			})
		)
	);

	constructor(private actions$: Actions, private dispensariesService: DispensaryService) {}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
