import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, USE_EMULATOR as FIRESTORE_EMULATOR } from '@angular/fire/firestore';
import { AngularFireAuthModule, USE_EMULATOR as AUTH_EMULATOR } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { AuthGuard } from './_shared/guards/auth.guard';
import { SignOutGuard } from './_shared/guards/sign-out.guard';
import * as moment from 'moment';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './_shared/http-interceptors';
import { UiSpinnerModule } from './_shared/ui-services/ui-spinner/ui-spinner.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StateModule } from './+state/state.module';
import { CustomMaterialModule } from './_shared/ui-services/custom-material.module';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

moment().locale('en-US');

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		CustomMaterialModule,
		LayoutModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFirestoreModule,
		AngularFireAuthModule,
		HttpClientModule,
		UiSpinnerModule,
		NgxSkeletonLoaderModule,
		AppRoutingModule,
		StoreModule.forRoot(
			{},
			{
				metaReducers: !environment.production ? [] : [],
				runtimeChecks: {
					strictActionImmutability: true,
					strictStateImmutability: true,
				},
			}
		),
		NgxMaskModule.forRoot(),
		EffectsModule.forRoot([]),
		!environment.production ? StoreDevtoolsModule.instrument() : [],
		StateModule,
	],
	providers: [
		AuthGuard,
		SignOutGuard,
		httpInterceptorProviders,
	
		// {
		// 	provide: AUTH_EMULATOR,
		// 	useValue: environment.production ? undefined : ['localhost', 9099],
		// },
		// {
		// 	provide: FIRESTORE_EMULATOR,
		// 	useValue: environment.production ? undefined : ['localhost', 8080],
		// },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
