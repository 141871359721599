import { Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatSpinner } from '@angular/material/progress-spinner';
import { Subject } from 'rxjs';
import { map, scan } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class UiSpinnerService {
	private spinnerTopRef: OverlayRef = this.cdkSpinnerCreate();
	spin$: Subject<boolean> = new Subject();
	isActive = false;

	constructor(private overlay: Overlay, private snackBar: MatSnackBar) {
		this.spin$
			.asObservable()
			.pipe(
				map((val) => (val ? 1 : -1)),
				scan((acc, one) => (acc + one >= 0 ? acc + one : 0), 0)
			)
			.subscribe((res) => {
				if (res === 1) {
					this.showSpinner();
				} else if (res === 0) {
					if (this.spinnerTopRef.hasAttached()) {
						this.stopSpinner();
					}
				}
			});
	}

	private cdkSpinnerCreate() {
		return this.overlay.create({
			hasBackdrop: true,
			backdropClass: 'sk-backdrop',
			positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
		});
	}

	showSpinner() {
		if (!this.isActive) {
			this.spinnerTopRef.attach(new ComponentPortal(MatSpinner));
			this.isActive = true;
		}
	}

	stopSpinner() {
		this.spinnerTopRef.detach();
		this.isActive = false;
	}

	showErrorSnackBar() {
		this.snackBar.open('We are currently experiencing problems fetching data, please try again later or contact support.', 'Error', {
			duration: 7000,
			horizontalPosition: 'center',
			verticalPosition: 'top',
		});
	}
}
